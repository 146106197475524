/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/theming';
@include mat-core();
@import 'app/core/theming/theme.scss';

.my-theme {
    @include angular-material-theme($my-theme);
  }

@include mat-core($mymobile-typography);
@include mat-base-typography($mymobile-typography);  

@media screen and (min-width: 1025px) {
  @include mat-core($my-typography);
  @include mat-base-typography($my-typography);  
}

body { margin: 0; 
       height: 100%;
       font-family: Roboto, "Helvetica Neue", sans-serif; 
       $background: map-get($my-theme, background);
       background-color: mat-color($background, background);
}
.mat-card-title {
  font-size: 18px;
  font-weight: 400;
}

.mat-toolbar {
  font-size: 14px;
  font-weight: 500;
}

.my-theme .mat-input-element {
  caret-color: black;
}

.mat-option.mat-selected {
  background: #307100 !important;
}

.mat-option.mat-selected > .mat-option-text {
  color: white;
}

.mat-option:hover, .mat-row:hover {
  background: #3071a9 !important;
}

.mat-option:hover > .mat-option-text {
  color: white;
}

.mat-row:hover > .mat-cell {
  color: white ;
}

@media screen and (max-width: 760px) {
  .mat-table {
    border: 0;
    vertical-align: middle
  }

  .mat-table caption {
    font-size: 1em;
  }
  
  .mat-table .mat-header-cell {
    
    border: 10px solid;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px;
  }
 
  .mat-table .mat-row  {
    border-bottom: 5px solid #ddd;
    display: block;
    height: auto;
  }
  /*
  .mat-table .mat-row:nth-child(even) {background: #CCC}
  .mat-table .mat-row:nth-child(odd) {background: #FFF}
  */
  .mat-table .mat-cell {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1em;
    text-align: right;
    min-height:30px;
    margin-bottom: 4%;
  }
  .mat-table .mat-cell:before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: normal;    
    font-size: .85em;
  }

  .mat-table .mat-cell:last-child {    
    border-bottom: 0;
  }

  .mat-table .mat-cell:first-child {
    margin-top: 4%;
  }

  td.mat-cell {
    padding: 4px !important;
  }
}



