/* For use in src/lib/core/theming/_palette.scss */

$app-primary: mat-palette($mat-grey, 300);
$app-accent: mat-palette($mat-blue);
$app-warn: mat-palette($mat-deep-orange, A200);

$my-theme: mat-light-theme(
    $app-primary, 
    $app-accent,
    $app-warn
);


$my-typography: mat-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $headline: mat-typography-level(16px, 26px, 500),
  $body-1: mat-typography-level(12px, 18px, 400)
);

$mymobile-typography: mat-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $headline: mat-typography-level(20px, 26px, 500),
  $body-1: mat-typography-level(14px, 18px, 400)
);

:root {
    --color-primary: #{mat-color($app-primary)};
    --color-accent: #{mat-color($app-accent)};
    --color-warn: #{mat-color($app-warn)};
    --color-highlight: #{mat-color($app-primary, 200)};
  }

